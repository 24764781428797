<template>
  <div class="meeting-provider-root">
    <div>
      <!-- <div
        v-for="participant in participants"
        :key="participant.id"
        class="meeting-audio"
      >
        <audio
          v-if="!participant.isLocal"
          :ref="'audio-' + participant.id"
          playsinline
          autoplay
        />
      </div> -->
      <audio
        ref="meetingAudio"
        playsinline
        autoplay
      />
    </div>
    <!-- <p>
      Token: {{ token  }}
    </p>
    <p>MeetingId: {{ videosdkMeetingId }}</p> -->
    <slot :meetingContext="meetingContext" />
    <!-- <div v-else>
      No meeting id!
    </div> -->
  </div>
</template>

<script>
  import { VideoSDK } from '@videosdk.live/js-sdk'

  import restApiService from '@/services/restApiService.js'

  const EVENTS = [
    'participant-joined',
    'participant-left',
    'presenter-changed',
    'main-participant-changed',
    'speaker-changed',
    'entry-requested',
    'entry-responded',
    'chat-message',
    'recording-started',
    'recording-stopped',
    'meeting-joined',
    'meeting-left',
    'livestream-started',
    'livestream-stopped',
    'video-state-changed',
    'video-seeked',
    'webcam-requested',
    'mic-requested',
    'pin-state-changed',
    'connection-open',
    'connection-close',
    'switch-meeting',
    'error',
  ]

  const PARTICIPANT_EVENTS = [
    'stream-enabled',
    'stream-disabled',
  ]

  export default {
    props: {
      meetingId: String,
      name: {
        type: String,
        default: 'Participant',
      },
      micEnabled: {
        type: Boolean,
        default: false,
      },
      webcamEnabled: {
        type: Boolean,
        default: false,
      },
      maxResolution: {
        type: String,
        default: 'hd',
      },
    },

    data: () => ({
      token: null,
      videosdkMeetingId: null,
      meeting: null,
      meetingJoined: false,

      audioStream: new MediaStream(),

      participants: {},
      pinnedParticipants: {},
      videoParticipants: {},
      audioParticipants: {},
      audioTracks: {},
      shareParticipants: {},
      speaker: null,
      nonLocalSpeaker: null,

      localWebcamOn: false,
      localMicOn: false,
      localScreenShareOn: false,

      muted: false,
    }),

    computed: {
      meetingContext() {
        return {
          meeting: this.meeting,
          meetingJoined: this.meetingJoined,
          localParticipant: this.meeting?.localParticipant,
          participants: this.participants,
          pinnedParticipants: this.pinnedParticipants,
          videoParticipants: this.videoParticipants,
          audioParticipants: this.audioParticipants,
          shareParticipants: this.shareParticipants,
          nonLocalSpeaker: this.nonLocalSpeaker,
          speaker: this.speaker,

          localWebcamOn: this.localWebcamOn,
          localMicOn: this.localMicOn,
          localScreenShareOn: this.localScreenShareOn,

          join: this.join,
          toggleWebcam: this.toggleWebcam,
          toggleMic: this.toggleMic,
          toggleScreenShare: this.toggleScreenShare,

          muted: this.muted,
          unmute: this.unmute,
        }
      },
    },

    watch: {
      token(val) {
        console.log('tokenChanged:', val)
        VideoSDK.config(val)
      },
    },

    beforeMount() {
      console.log('MeetingProvider -> getUserMedia is supported', !!navigator?.mediaDevices?.getUserMedia)

      this.createToken()

      window.meetingProvider = this
    },

    mounted() {
      this.$refs.meetingAudio.srcObject = this.audioStream
      this.playOrShowButton()
    },

    destroyed() {
      if (this.meeting) this.meeting.leave()
    },

    methods: {
      createToken() {
        restApiService.get('/api/videosdk/get-token')
          .then(res => {
            this.token = res.data.token
          })
          .then(this.createMeeting)
          .catch(this.showErrorMsg)
      },

      createMeeting() {
        const createMeetingBody = {
          token: this.token,
          topic: this.meetingId,
        }
        restApiService.post('/api/videosdk/create-meeting', createMeetingBody)
          .then(res => {
            this.videosdkMeetingId = res.data.meetingId
          }).then(this.join)
      },

      join() {
        this.initMeeting()
        this.initEvents()
        this.meeting.join()
      },

      leave() {
        if (!this.meeting) {
          return
        }

        const endMeeting = this.meeting.participants.size() <= 1
        this.meeting.leave()
        if (endMeeting) this.meeting.end()
      },

      toggleWebcam() {
        console.log('toggleWebcam')
        if (this.localWebcamOn) {
          this.meeting.disableWebcam()
          this.localWebcamOn = false
        } else {
          this.meeting.enableWebcam()
          this.localWebcamOn = true
        }
      },

      toggleMic() {
        console.log('toggleMic')
        if (this.localMicOn) {
          this.meeting.muteMic()
          this.localMicOn = false
        } else {
          this.meeting.unmuteMic()
          this.localMicOn = true
        }
      },

      toggleScreenShare() {
        console.log('toggleScreenShare')
        if (this.localScreenShareOn) {
          this.meeting.disableScreenShare()
          this.localScreenShareOn = false
        } else {
          this.meeting.enableScreenShare()
          this.localScreenShareOn = true
        }
      },

      unmute() {
        this.playOrShowButton()
      },

      initMeeting() {
        this.meeting = VideoSDK.initMeeting({
          meetingId: this.videosdkMeetingId, // required
          name: this.name, // required
          micEnabled: this.micEnabled, // optional, default: true
          webcamEnabled: this.webcamEnabled, // optional, default: true
          maxResolution: this.maxResolution, // optional, default: "hd"
        })
      },

      initEvents() {
        EVENTS.forEach((eventName) => {
          const handlerName = `on${this.upperCamelize(eventName)}`
          // console.log(eventName, handlerName)
          this.meeting.on(eventName, this[handlerName])
        })
        PARTICIPANT_EVENTS.forEach((eventName) => {
          const localHandlerName = `onLocal${this.upperCamelize(eventName)}`
          this.meeting.localParticipant.on(eventName, this[localHandlerName])
        })
      },

      upperCamelize(str) {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
          return word.toUpperCase()
        }).replace(/[\s|-]+/g, '')
      },

      initParticipants() {
        // TODO: Remove this
        for (const participant of this.meeting.participants.values()) {
          this.$set(this.participant, participant.id, participant)
        }

        for (const participant of this.meeting.pinnedParticipants.values()) {
          this.$set(this.pinnedParticipants, participant.id, participant)
        }
      },

      onMeetingJoined() {
        console.log('onMeetingJoined')
        this.$set(this.participants, this.meeting.localParticipant.id, this.meeting.localParticipant)

        this.setParticipantVideoAudioCallback(this.meeting.localParticipant)
        this.meetingJoined = true
        this.meeting.localParticipant.setQuality('high')
        this.initParticipants()
      },

      onMeetingLeft() {
        console.log('onMeetingLeft')
        this.meetingJoined = false
      },

      onParticipantJoined(participant) {
        console.log('onParticipantJoined', participant)
        this.$set(this.participants, participant.id, participant)
        this.setParticipantVideoAudioCallback(participant)
      },

      setParticipantVideoAudioCallback(participant) {
        participant.on('stream-enabled', stream => {
          console.log('setParticipantVideoAudioCallback :: enabled', stream, participant)
          if (stream.kind === 'video') {
            this.$set(this.videoParticipants, participant.id, participant)
          } else if (stream.kind === 'audio') {
            this.$set(this.audioParticipants, participant.id, participant)
            this.setAudioStream(stream, participant)
          } else if (stream.kind === 'share') {
            this.$set(this.shareParticipants, participant.id, participant)
            if (participant.id !== this.meeting.localParticipant.id && this.localScreenShareOn) {
              this.toggleScreenShare()
            }
          }
        })

        participant.on('stream-disabled', stream => {
          console.log('setParticipantVideoAudioCallback :: disabled', stream, participant)
          if (stream.kind === 'video') {
            this.$delete(this.videoParticipants, participant.id)
          } else if (stream.kind === 'audio') {
            this.$delete(this.audioParticipants, participant.id)
            this.$delete(this.audioTracks, participant.id)
            this.audioStream.removeTrack(stream.track)
          } else if (stream.kind === 'share') {
            this.$delete(this.shareParticipants, participant.id)
          }
        })
      },

      setAudioStream(stream, participant) {
        if (this.meeting.localParticipant.id === participant.id) {
          return
        }
        this.$set(this.audioTracks, participant.id, stream.track)
        this.audioStream.addTrack(stream.track)

        this.$refs.meetingAudio.play()
        this.playOrShowButton()
      },

      playOrShowButton() {
        this.$refs.meetingAudio.play().then(() => {
          this.muted = false
        }).catch((e) => {
          console.error('Couldn\'t start playing AUDIO', typeof e, e)
          if (e.name === 'NotAllowedError') {
            this.muted = true
          }
        })
      },

      onParticipantLeft(participant) {
        console.log('onParticipantLeft', participant)
        this.$delete(this.participants, participant.id)
        this.$delete(this.pinnedParticipants, participant.id)
        this.$delete(this.videoParticipants, participant.id)
        this.$delete(this.shareParticipants, participant.id)

        // Fix https://redmine.connexence.com/issues/64990
        // Tracks weren't being removed properly, thus corrupting the audio playback of others when you left
        // Probably caused by VideoSDK not sending a "stream-disabled" event
        const track = this.audioTracks[participant.id]
        this.audioStream.removeTrack(track)
        this.$delete(this.audioTracks, participant.id)

        if (participant.id === this.speaker) {
          this.speaker = null
        }
        if (participant.id === this.nonLocalSpeaker) {
          this.nonLocalSpeaker = null
        }
      },

      onPinStateChanged(arg) {
        console.log('onPinStateChanged', arg)
        if (arg.state.cam || arg.state.share) {
          this.$set(this.pinnedParticipants, arg.participantId, this.participants[arg.participantId])
        } else {
          this.$delete(this.pinnedParticipants, arg.participantId)
        }
      },

      onSpeakerChanged(speaker) {
        console.log('onSpeakerChanged', speaker)
        if (speaker != null) {
          this.speaker = speaker
          if (speaker !== this.meeting.localParticipant.id) {
            this.nonLocalSpeaker = speaker
          }
        }
      },

      onLocalStreamEnabled(stream) {
        console.log('onLocalStreamEnabled', stream)
        if (stream.kind === 'video') {
          this.localWebcamOn = true
        } else if (stream.kind === 'audio') {
          this.localMicOn = true
        }
      },

      onLocalStreamDisabled(stream) {
        console.log('onLocalStreamDisabled', stream)
        if (stream.kind === 'video') {
          this.localWebcamOn = false
        } else if (stream.kind === 'audio') {
          this.localMicOn = true
        }
      },

      onPresenterChanged(presenter) {
        console.log('onPresenterChanged', presenter)
      },

      onMainParticipantChanged(participant) {
        console.log('onMainParticipantChanged', participant)
      },

      onEntryRequested(entry) {
        console.log('onEntryRequested', entry)
      },

      onEntryResponded(entry) {
        console.log('onEntryResponded', entry)
      },

      onChatMessage(msg) {
        console.log('onChatMessage', msg)
      },

      onRecordingStarted() {
        console.log('onRecordingStarted')
      },

      onRecordingStopped() {
        console.log('onRecordingStopped')
      },

      onLivestreamStarted() {
        console.log('onLivestreamStarted')
      },

      onLivestreamStopped() {
        console.log('onLivestreamStopped')
      },

      onVideoStateChanged() {
        console.log('onVideoStateChanged')
      },

      onVideoSeeked() {
        console.log('onVideoSeeked')
      },

      onWebcamRequested() {
        console.log('onWebcamRequested')
      },

      onMicRequested() {
        console.log('onMicRequested')
      },

      onConnectionOpen() {
        console.log('onConnectionOpen')
      },

      onConnectionClose() {
        console.log('onConnectionClose')
      },

      onSwitchMeeting() {
        console.log('onSwitchMeeting')
      },

      onError(error) {
        console.log('onError', error)
        this.showErrorMsg()
      },

      showErrorMsg(error) {
        console.error('VideoSDK error during init', error)
        alert("Erreur lors de l'initialisation du kiosque, veuillez tenter de rafraichir votre page.")
      },
    },
  }
</script>

<style scoped>
.meeting-provider-root {
  width: 100%;
}
</style>
