<template>
  <v-card
    outlined
  >
    <v-card-text>
      <div>
        <v-row
          v-if="subscribers.length == 0"
          align="center"
          justify="center"
          style="height:240px;"
        >
          <v-img
            :src="urlImageSansVideo()"
            height="240"
            contain
          />
        </v-row>
        <div ref="containerVideo" />
        <v-btn
          v-if="muted"
          dark
          small
          class="green white--text"
          @click="unmute"
        >
          <v-icon>
            mdi-volume-high
          </v-icon>
          <span class="pl-3">
            {{ $t('general.activer_le_son') }}
          </span>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
  import { OpenVidu } from 'openvidu-browser'
  import restApiService from '@/services/restApiService.js'
  import Vue from 'vue'

  export default {
    components: {
    },
    props: {
      kiosque: Object,
    },
    data: () => ({
      OV: undefined,
      session: undefined,
      token: undefined,
      mainStreamManager: undefined,
      subscribers: [],
      videoElements: {},
      width: 320,
      height: 240,
      muted: false,

    }),
    created () {
      console.log('OvParticipant -> created')
      this.initOpenVidu()
    },
    methods: {
      initOpenVidu () {
        const options = {
          loglevel: 2, // https://github.com/OpenVidu/openvidu/issues/243
        }
        this.OV = new OpenVidu(options)

        this.session = this.OV.initSession()
        this.session.on('streamCreated', ({ stream }) => {
          // console.log('OvKiosque->streamCreated')
          const subscriber = this.session.subscribe(stream, this.$refs.containerVideo)
          this.subscribers.push(subscriber)

          subscriber.on('videoElementCreated', event => {
            const vidEl = event.element
            this.videoElements[vidEl.id] = vidEl
            vidEl.autoplay = true
            vidEl.playsinline = true
            vidEl.style = 'width:320px;'
            setTimeout(() => {
              vidEl.play().then(() => {
                console.error('Can play media unmuted')
              }).catch(() => {
                console.error('Can\'t play media unmuted')
                this.$emit('playblocked', true)
                vidEl.muted = true
                this.muted = true
                vidEl.play().then(() => console.error('Can play if muted')).catch((err) => console.error('Still fails even muted', err))
              })
            }, 100)
          })

          subscriber.on('videoElementDestroyed', event => {
            const vidEl = event.element
            Vue.delete(this.videoElements, vidEl.id)
            if (this.videoElements.length === 0) {
              this.muted = false
            }
          })
        })

        this.session.on('streamPlaying', ({ stream }) => {
          // console.log('OvKiosque->streamPlaying')
        })
        // On every Stream destroyed...
        this.session.on('streamDestroyed', ({ stream }) => {
          const index = this.subscribers.indexOf(stream.streamManager, 0)
          if (index >= 0) {
            this.subscribers.splice(index, 1)
          }
        })

        const context = `ORKA-K-${this.kiosque.id}`

        restApiService.post('/api/openvidu/api-sessions/get-token',
                            {
                              sessionName: context,
                              context: context,
                            },
        )
          .then((response) => {
            this.token = response.data[0]
            this.session.connect(this.token)
              .then(() => {
                // console.log('OvKiosque->connected')
              })
              .catch(error => {
                console.error('There was an error connecting to the session:', error)
              })
          },
          )
        window.addEventListener('beforeunload', this.leaveSession)
      },

      leaveSession () {
        // --- Leave the session by calling 'disconnect' method over the Session object ---
        if (this.session) this.session.disconnect()

        this.session = undefined
        this.mainStreamManager = undefined
        this.subscribers = []
        this.OV = undefined

        window.removeEventListener('beforeunload', this.leaveSession)
      },

      unmute () {
        Object.keys(this.videoElements).forEach(key => {
          const el = this.videoElements[key]
          el.muted = false
        })
        this.muted = false
      },

      updateMainVideoStreamManager (stream) {
        if (this.mainStreamManager === stream) return
        this.mainStreamManager = stream
      },
      urlImageSansVideo () {
        return restApiService.mediaUrl(this.kiosque.mediaImage)
      },

    },
  }
</script>
