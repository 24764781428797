<template>
  <div
    ref="root"
    class="participant-viewer"
  >
    <v-icon
      v-if="muted"
      class="muted-indicator"
    >
      mdi-microphone-off
    </v-icon>
    <video
      ref="video"
      playsinline
      autoplay
      muted
      :style="videoStream ? '' : 'display: none;'"
    />
    <div
      ref="token"
      :style="videoStream ? 'display: none;' : ''"
      class="token"
    >
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col style="text-align: center;">
            <div
              ref="tokenCircle"
              class="token-circle"
            >
              {{ initials }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      participant: Object,
      screenShare: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      videoStream: null,
      intervalHeight: null,

      // Only for the mic indicator, not linked to sound output/input
      muted: false,
    }),

    computed: {
      streamType() {
        return this.screenShare ? 'share' : 'video'
      },

      initials() {
        const words = this.participant.displayName.split(' ')
        return words.map(w => w.length > 0 ? w.charAt(0) : '').join('')
      },

      tokenColor() {
        return this.stringToColour(this.participant.id)
      },
    },

    watch: {
      screenShare(val) {
        this.participant.streams.forEach(this.applyStream)
      },
    },

    mounted() {
      this.participant.streams.forEach(this.applyStream)

      this.participant.on('stream-enabled', this.onStreamEnabled)
      this.participant.on('stream-disabled', this.onStreamDisabled)

      this.intervalHeight = setInterval(this.setHeight, 100)

      this.$refs.tokenCircle.style['background-color'] = this.tokenColor

      this.checkMuted()
    },

    destroyed() {
      this.participant.off('stream-enabled', this.onStreamEnabled)
      this.participant.off('stream-disabled', this.onStreamDisabled)

      clearInterval(this.intervalHeight)
    },

    methods: {
      onStreamEnabled(stream) {
        console.log('Stream enabled', this.participant.id, stream)
        this.applyStream(stream)
        this.checkMuted(stream, true)
      },

      onStreamDisabled(stream) {
        console.log('Stream disabled', this.participant.id, stream)
        if (stream.kind === this.streamType) {
          this.videoStream = null
        }
        this.checkMuted(stream, false)
      },

      applyStream(stream) {
        if (stream.kind === this.streamType) {
          this.setVideoStream(stream)
        }
      },

      setVideoStream(stream) {
        this.videoStream = new MediaStream()
        this.videoStream.addTrack(stream.track)
        this.$refs.video.srcObject = this.videoStream
        this.$refs.video.play().catch((e) => console.error(`Couldn't start playing VIDEO for ${this.participant.id}`, e))
      },

      checkMuted(stream, enabled) {
        console.log('checkMuted')
        let muted = true
        if (stream?.kind === 'audio') {
          muted = !enabled
        } else {
          this.participant.streams.forEach(({ kind }) => {
            if (kind === 'audio') muted = false
          })
        }
        this.muted = muted
      },

      setHeight() {
        const root = this.$refs.root
        const token = this.$refs.token
        const width = root.offsetWidth
        root.style.height = `${width / 1.67}px`
        token.style.height = root.style.height
      },

      stringToColour(str) {
        let hash = 0
        for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash)
        }
        let colour = '#'
        for (let j = 0; j < 3; j++) {
          const value = (hash >> (j * 8)) & 0xFF
          colour += ('00' + value.toString(16)).substr(-2)
        }
        return colour
      },
    },
  }
</script>

<style scoped>
.participant-viewer {
  position: relative;
  background-color: #3d3f42
}
.participant-viewer, video {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

.token {
  background-color: #3d3f42;
  border-radius: 16px;
}

.token-circle {
  border-radius: 50%;
  height: 64px;
  width: 64px;
  margin: 0 auto;
  line-height: 64px;
  font-size: 1.4em;
}

.muted-indicator {
  color: white;
  position: absolute;
  top: 8px;
  left: 8px;
}
</style>
