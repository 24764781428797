<template>
  <v-container fluid>
    <h1 class="font-weight-light mb-2 headline">
      {{ kiosque.nom }}
    </h1>

    <v-row>
      <meeting-provider
        v-if="fournisseurRtc.valeur === 'videosdk' && chaine && usager"
        v-slot="{ meetingContext }"
        :name="usager.prenom"
        :meeting-id="chaine"
        class="my-4"
      >
        <kiosque-meeting-container
          v-if="meetingContext.meetingJoined"
          :context="meetingContext"
          :kiosque="kiosque"
          :exposant="mode == 'exposant'"
        />
        <v-row v-else>
          <v-img
            class="my-4"
            :src="urlImageSansVideo()"
            :width="320"
            :height="240"
            contain
          />
        </v-row>
      </meeting-provider>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="7"
        lg="6"
      >
        <div v-if="fournisseurRtc.valeur === 'openvidu'">
          <ov-exposant
            v-if="mode == 'exposant'"
            :kiosque="kiosque"
          />
          <ov-participant
            v-if="mode == 'participant'"
            :kiosque="kiosque"
          />
        </div>
        <base-material-card
          color="success"
          icon="mdi-information-outline"
          inline
          :title="$t('general.info')"
          class="px-5 py-3 mb-5"
        >
          <div v-html="kiosque.description" />
          <p v-if="kiosque.urlWeb">
            {{ $t('kiosque.lien') }} :
            <a
              :href="kiosque.urlWeb"
              target="_blank"
            >
              {{ kiosque.urlWeb }}
            </a>
          </p>
          <v-btn
            v-if="mode == 'exposant'"
            small
            class="primary"
            @click="
              $router.push({ path: `/orka/kiosque/edition/${kiosque.id}` })
            "
          >
            <v-icon left>
              mdi-cog
            </v-icon>
            {{ $t('kiosque.modifier_les_renseignements_du_kiosque') }}
          </v-btn>
        </base-material-card>
        <base-material-card
          color="success"
          icon="mdi-file-document"
          inline
          :title="$t('kiosque.matériel')"
          class="px-5 py-3 mb-5"
        >
          <div v-if="mode == 'participant'">
            <div
              v-for="document in documents"
              :key="document.id"
            >
              <v-card class="m-2">
                <v-list class="p-0">
                  <v-list-item
                    style="height:60px"
                    class="p-0"
                    @click="onTéléchargerDocument(document)"
                  >
                    <v-list-item-avatar>
                      <v-icon
                        large
                        color="purple"
                      >
                        {{ iconeFileType(document) }}
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title style="white-space:normal">
                        {{ document.nom }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ document.description }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </div>
          </div>
          <boite-documents
            v-if="mode == 'exposant'"
            :kiosque="kiosque"
            @maj="majDocuments()"
          />
        </base-material-card>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="5"
        lg="6"
      >
        <chat
          :chaine="chaine"
          :formateur="mode == 'exposant'"
          :user-info="$store.state.userInfo"
          :titre="$t('kiosque.clavardage')"
        />
        <v-card>
          <v-card-title>
            {{ $t('kiosque.invitation_salon_prive') }}
          </v-card-title>
          <v-card-text class="d-flex">
            <div class="align-self-center">
              {{ $t('general.inviter') }} :
            </div>
            <v-select
              v-model="selectionInvitation"
              :no-data-text="$t('kiosque.desole_personne_n_est_disponible')"
              :items="listeInvités"
              item-value="usager"
              class="px-4"
            >
              <template v-slot:selection="data">
                {{ data.item.usager.prenom }} {{ data.item.usager.nom }}
              </template>
              <template v-slot:item="data">
                {{ data.item.usager.prenom }} {{ data.item.usager.nom }}
              </template>
            </v-select>
            <v-btn
              class="align-self-center primary"
              small
              @click="onInviterSalonPrivé()"
            >
              {{ $t('general.inviter') }}
            </v-btn>
          </v-card-text>
        </v-card>
        <v-card>
            <rapport-presence
              v-if="mode == 'exposant'"
              ref="rapportPresence"
              :notification-entree.sync="notificationSonore"
              :salon="chaine"
              :usagers-presents="listeUsagersPrésents"
            />
        </v-card>
</v-col>
    </v-row>
  </v-container>
</template>
<script>
  import restApiService from '@/services/restApiService.js'
  import notificationSonore from '@/services/notificationSonore.js'
  import Chat from '../../agora/Chat'
  import BoiteDocuments from './../composantes/BoiteDocuments'
  import Salon from '@/services/salon'
  import RapportPresence from '../composantes/RapportPresence'
  import Gestionpresence from '@/services/gestionpresence.js'
  import MeetingProvider from '../../videosdk/MeetingProvider'
  import KiosqueMeetingContainer from '../../videosdk/KiosqueMeetingContainer'
  import OvExposant from '@/views/composantes/OvExposant'
  import OvParticipant from '@/views/composantes/OvParticipant'
  import { mapGetters } from 'vuex'
  import ToucanCom from '@/services/toucanCom.js'

  export default {
    components: {
      Chat,
      BoiteDocuments,
      RapportPresence,
      MeetingProvider,
      KiosqueMeetingContainer,
      OvExposant,
      OvParticipant,
    },
    data: () => ({
      kiosque: {},
      usager: null,
      mode: true,
      documents: [],
      idNotifSalon: null,
      selectionInvitation: null,
      salonKiosque: undefined,
      infoSalonKiosque: {},
      gestionPresence: null,
      notificationSonore: true,
    }),
    computed: {
      ...mapGetters(['fournisseurRtc']),

      chaine() {
        return `ORKA-K-${this.kiosque.id}-${this.$store.state.evenementActif.id}`
      },
      listeInvités() {
        if (this.infoSalonKiosque.listePrésences) {
          // on ne veut pas s'inviter soi même :)
          return this.infoSalonKiosque.listePrésences.filter(
            p => p.usager.id !== this.$store.state.userInfo.id,
          )
        }
        return []
      },
      listeUsagersPrésents() {
        return this.infoSalonKiosque.listePrésences?.map(u => u.usager.nomUsager)
      },
    },
    created() {
      window.addEventListener('beforeunload', this.leaving)
      ToucanCom.init(this.$root.$store.state.userInfo)
    },
    mounted() {
      this.chargerDonnées()
      this.getInfoUsager()
    },
    beforeDestroy() {
      this.leaving()
    },
    methods: {
      leaving() {
        this.salonKiosque.sortir()
        this.gestionPresence.arreter()
        this.gestionPresence = null
        ToucanCom.getInstance().supprimerEcouteur()
      },
      initPresence() {
        if (!this.gestionPresence) {
          this.gestionPresence = new Gestionpresence(this.$store.state.userInfo.id, this.chaine)
          this.gestionPresence.demarrer()
        }
      },
      chargerDonnées() {
        restApiService
          .get('/api/webUser/infoKiosque/' + this.$route.params.idKiosque)
          .then(result => {
            this.kiosque = result.data.kiosque
            this.documents = result.data.listeDocuments
            if (result.data.estResponsable) {
              this.mode = 'exposant'
            } else {
              this.mode = 'participant'
            }
            if (!this.salonKiosque) {
              this.salonKiosque = Salon.initKiosque(this.$route.params.idKiosque)
              this.salonKiosque.ajouterNotificationEntréeSortie(this.onMajSalon)
              this.salonKiosque.entrer()
              this.onMajSalon()
            }
            console.log('salonKiosque', this.salonKiosque)
            this.initPresence()
          })
          .catch(error => alert(error))
      },

      getInfoUsager() {
        restApiService
          // .get(`/api/usagers/${this.$store.state.userInfo.id}`)
          .get('/api/webUser/infoUsager')
          .then((result) => {
            this.usager = result.data.usager
          })
          .catch((erreur) => alert(erreur))
      },

      iconeFileType(document) {
        if (document.fileType.match(/image/g)) {
          return 'mdi-image'
        }
        if (document.fileType.match(/pdf/g)) {
          return 'mdi-file-pdf'
        }
        if (document.fileType.match(/zip/g)) {
          return 'mdi-folder-zip'
        }
        return 'mdi-file'
      },
      onTéléchargerDocument(doc) {
        restApiService.post(`/api/webUser/visite/document/${doc.id}`)
        restApiService
          .getblob(`/api/documents/${doc.id}/telecharger/`)
          .then(response => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]))
            var fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.setAttribute('download', doc.nom)
            document.body.appendChild(fileLink)
            fileLink.click()
          })
          .catch(error => alert(error))
      },
      majDocuments() {
        this.chargerDonnées()
      },
      onMajSalon(e) {
        if (e?.action === 'entrer' && this.notificationSonore && e.info !== this.$store.state.userInfo.nomUsager) {
          notificationSonore.jouer()
        }
        this.$refs.rapportPresence?.getInfoPrésence()
        this.salonKiosque.getSalon().then(info => {
          this.infoSalonKiosque = info
        })
      },
      onInviterSalonPrivé() {
        const usagerHôte = this.$store.state.userInfo
        const usagerInvité = this.selectionInvitation
        const salon = Salon.initSalonPrivé(usagerHôte, usagerInvité)
        Salon.inviterSalonPrivé(usagerHôte, usagerInvité, salon.identifiant)
      },

      urlImageSansVideo() {
        return restApiService.mediaUrl(this.kiosque.mediaImage)
      },
    },
  }
</script>
