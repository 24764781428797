<template>
  <v-card outlined>
    <v-card-text>
      <v-img
        v-if="!publisher"
        class="my-4"
        :src="urlImageSansVideo()"
        :width="width"
        :height="height"
        contain
      />

      <div
        v-if="publisher"
        id="session"
        style="height:272px"
      >
        <div id="main-video">
          <user-video :stream-manager="publisher" />
        </div>
      </div>

      <!-- VIDEO -->
      <v-btn
        :class="partageCamera ? 'success mx-1' : 'grey mx-1'"
        icon
        @click="toggleCamera()"
      >
        <v-icon>mdi-video-outline</v-icon>
      </v-btn>

      <!-- AUDIO -->
      <v-btn
        :class="partageMicro ? 'success mx-1' : 'grey mx-1'"
        icon
        @click="toggleMicro()"
      >
        <v-icon>mdi-microphone</v-icon>
      </v-btn>

      <!-- PARTAGE ÉCRAN -->
      <v-btn
        :class="partageÉcran ? 'success mx-1' : 'grey mx-1'"
        icon
        @click="toggleEcran()"
      >
        <v-icon>mdi-monitor-screenshot</v-icon>
      </v-btn>

      <hr class="my-3">
      <div ref="containerVideo" />
      <v-btn
        v-if="muted"
        dark
        small
        class="green white--text"
        @click="unmute"
      >
        <v-icon>
          mdi-volume-high
        </v-icon>
        <span class="pl-3">
          {{ $t('general.activer_le_son') }}
        </span>
      </v-btn>
    </v-card-text>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      <span class="text-md-body-1">
        {{ snackbarText }}
      </span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('general.fermer') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>
<script>
  import UserVideo from './UserVideo'
  import { OpenVidu } from 'openvidu-browser'
  import restApiService from '@/services/restApiService.js'
  import Vue from 'vue'
  import i18n from '@/i18n.js'

  export default {
    components: {
      UserVideo,
    },
    props: {
      kiosque: Object,
    },
    data: () => ({
      autoplayDisabled: false,
      OV: undefined,
      session: undefined,
      token: undefined,
      publisher: undefined,
      subscribers: [],
      videoElements: {},
      width: 320,
      height: 240,
      partageCamera: false,
      partageMicro: false,
      partageÉcran: false,
      videoSource: undefined,
      muted: false,
      snackbar: false,
      snackbarText: '',
      timeout: 4000,
    }),

    computed: {
      publishAudio() {
        return this.partageMicro
      },
      publishVideo() {
        return this.partageCamera || this.partageÉcran
      },
      etaitPartageEcran() {
        return this.videoSource === 'screen'
      },
      etaitPartageCamera() {
        return typeof this.videoSource === 'undefined'
      },
    },

    created() {
      console.log('OvExposant -> created')
      setTimeout(() => {
        this.initOpenVidu()
      })
      window.ovExposantVm = this
    },
    beforeDestroy() {
      setTimeout(() => {
        this.leaveSession()
      })
    },

    methods: {
      initOpenVidu() {
        console.log(`
        *****************************
        *
        *  Openvidu new version!!!! 21:14
        *
        *****************************`)
        this.OV = new OpenVidu()
        this.session = this.OV.initSession()
        this.session.on('streamCreated', ({ stream }) => {
          console.log('OvKiosque->streamCreated')
          const subscriber = this.session.subscribe(
            stream,
            this.$refs.containerVideo,
          )
          this.subscribers.push(subscriber)

          subscriber.on('videoElementCreated', event => {
            const vidEl = event.element
            this.videoElements[vidEl.id] = vidEl
            vidEl.autoplay = true
            vidEl.playsinline = true
            if (event.target.stream.typeOfVideo === 'SCREEN') {
              vidEl.classList.add('col-12')
            } else {
              vidEl.style = 'width:320px;'
            }
            setTimeout(() => {
              vidEl
                .play()
                .then(() => {
                  console.error('Can play media unmuted')
                })
                .catch(() => {
                  console.error("Can't play media unmuted")
                  this.$emit('playblocked', true)
                  vidEl.muted = true
                  this.muted = true
                  vidEl
                    .play()
                    .then(() => console.error('Can play if muted'))
                    .catch(err => console.error('Still fails even muted', err))
                })
            }, 100)
          })

          subscriber.on('videoElementDestroyed', event => {
            const vidEl = event.element
            Vue.delete(this.videoElements, vidEl.id)
            if (this.videoElements.length === 0) {
              this.muted = false
            }
          })
        })

        this.session.on('streamPlaying', ({ stream }) => {
          console.log('OvKiosque->streamPlaying')
        })
        // On every Stream destroyed...
        this.session.on('streamDestroyed', ({ stream }) => {
          console.log('OvKiosque->streamDestroyed')
          const index = this.subscribers.indexOf(stream.streamManager, 0)
          if (index >= 0) {
            this.subscribers.splice(index, 1)
          }
        })

        const context = `ORKA-K-${this.kiosque.id}`
        console.log('context: ' + context)

        restApiService
          .post('/api/openvidu/api-sessions/get-token', {
            sessionName: context,
            context: context,
          })
          .then(response => {
            this.token = response.data[0]
            console.log('OvKiosque->token' + this.token)
            this.session
              .connect(this.token)
              .then(() => {
                console.log('OvKiosque->connected')
              })
              .catch(error => {
                console.error(
                  'There was an error connecting to the session:',
                  error.code,
                  error.message,
                )
              })
          })
        window.addEventListener('beforeunload', this.leaveSession)
      },

      updateMainVideoStreamManager(sub) {
        console.log(sub)
      },

      unmute() {
        Object.keys(this.videoElements).forEach(key => {
          const el = this.videoElements[key]
          el.muted = false
        })
        this.muted = false
      },

      toggleCamera() {
        console.log('OvKiosque->toggleCamera')

        if (this.partageCamera) {
          this.partageCamera = false
        } else {
          this.partageCamera = true

          if (this.partageÉcran) {
            this.partageÉcran = false
          }
        }

        if (this.publisher && !this.etaitPartageEcran) {
          try {
            this.onPublishVideo(true)
          } catch (e) {
            setTimeout(this.onPublishVideo, 1000)
          }
        } else {
          this.republish()
        }
      },

      toggleMicro() {
        this.partageMicro = !this.partageMicro
        if (this.publisher) {
          try {
            this.onPublishAudio(true)
          } catch (e) {
            setTimeout(this.onPublishAudio, 1000)
          }
        } else {
          this.republish()
        }
      },

      onPublishAudio(firstTry) {
        try {
          this.publisher.publishAudio(this.publishAudio)

          if (!this.publishVideo && !this.publishAudio) {
            this.session.unpublish(this.publisher)
            this.publisher = null
          }
        } catch (e) {
          if (firstTry) {
            throw e
          } else {
            console.error(e)
            this.snackbar = true
            this.snackbarText = i18n.t('general.Une_erreur_s_est_produite_lors_de_l_activation_du_micro')
          }
        }
      },

      toggleEcran(publish) {
        console.log('OvKiosque->toggleEcran')

        if (this.partageÉcran) {
          this.partageÉcran = false
        } else {
          this.partageÉcran = true

          if (this.partageCamera) {
            this.partageCamera = false
          }
        }

        if (this.publisher && !this.etaitPartageCamera) {
          try {
            this.onPublishVideo(true)
          } catch (e) {
            setTimeout(this.onPublishVideo, 1000)
          }
        } else {
          this.republish()
        }
      },

      onPublishVideo(firstTry) {
        try {
          this.publisher.publishVideo(this.publishVideo)
          if (!this.publishVideo && !this.publishAudio) {
            this.session.unpublish(this.publisher)
            this.publisher = null
          }
        } catch (e) {
          if (firstTry) {
            throw e
          } else {
            console.error(e)
            this.snackbar = true
            this.snackbarText = i18n.t('general.Une_erreur_s_est_produite_lors_de_l_activation_de_la_camera')
          }
        }
      },

      leaveSession() {
        console.log('OvKiosque->leaveSession')
        // --- Leave the session by calling 'disconnect' method over the Session object ---
        if (this.session) this.session.disconnect()

        this.session = undefined
        this.publisher = undefined
        this.subscribers = []
        this.OV = undefined

        window.removeEventListener('beforeunload', this.leaveSession)
      },

      urlImageSansVideo() {
        return restApiService.mediaUrl(this.kiosque.mediaImage)
      },

      republish() {
        if (this.publisher) {
          this.session.unpublish(this.publisher)
          this.publisher = null
        }

        setTimeout(() => {
          console.log(
            `Publish video ${this.publishVideo}, publish audio ${
            this.publishAudio
          }, video source: ${
            this.publisher ? this.publisher.videoSource : 'none'
            }`,
          )

          if (this.partageCamera || this.partageMicro || this.partageÉcran) {
            let newVideoSource
            if (this.partageÉcran) {
              newVideoSource = 'screen'
            }

            this.videoSource = newVideoSource

            const publisher = this.OV.initPublisher(
              undefined,
              {
                audioSource: undefined, // The source of audio. If undefined default microphone
                videoSource: newVideoSource, // The source of video. If undefined default webcam
                publishAudio: this.publishAudio, // Whether you want to start publishing with your audio unmuted or not
                publishVideo: this.publishVideo, // Whether you want to start publishing with your video enabled or not
                resolution: `${this.width}x${this.height}`, // The resolution of your video
                frameRate: 30, // The frame rate of your video
                insertMode: 'APPEND', // How the video is inserted in the target element 'video-container'
                mirror: false, // Whether to mirror your local video or not
              },
              error => {
                // Function to be executed when the method finishes
                if (error) {
                  console.error('Error while initializing publisher: ', error)
                } else {
                  console.log('Publisher successfully initialized')
                }
              },
            )

            this.publisher = publisher
            this.session.publish(this.publisher)
          } else {
            this.publisher = null
          }
        }, 200)
      },
    },
  }
</script>
